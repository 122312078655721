import { GridColDef } from '@mui/x-data-grid'
import { useContext, useEffect, useMemo, useState } from 'react'
import { ReactComponent as DollarIcon } from '../../assets/dollar.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon-white.svg'
import { ReactComponent as NonPaymentIcon } from '../../assets/non-payment.svg'
import { ReactComponent as ReceiptX } from '../../assets/receipt-x.svg'
import { ReactComponent as ReceivedAmountIcon } from '../../assets/received-amount.svg'
import { ActionTableButton } from '../../components/Table/ActionTableButton'
import { WidgetPayments } from '../../components/WidgetPayments'
import {
  downloadCsvGet,
  downloadCsvGetAllgreen,
  paymentsInfoGet,
  paymentsInfoGetAllgreen,
} from '../../services/requests/user-requests'
import {
  ConfigButtonsColumn,
  FiltersContainer,
  PaymentsContainer,
  SettingsContainer,
  WidgetsContainer,
} from './styles'
import { PaymentsInfoData } from './types'
import { DateFilter } from '../../components/Filter/DateFilter'
import { PaymentsBalanceSheet } from '../../interfaces/payments'
import { paymentsService } from '../../services/payments'
import CompensationModalityFilter from '../Credits/Balance/components/CompensationModalityFilter'
import './style.css'
import { usePermissions } from '../../hooks/usePermissions'
import { PermissionType } from '../../interfaces/permissions'
import { Sort } from '../../interfaces/conference'
import { GlobalContext } from '../../context/global/global'
import { PreviewInvoiceModal } from './components/PreviewInvoiceModal'
import { useToast } from '../../hooks/useToast'
import SearchInput from '../Tickets/components/SearchInput'
import DueDateSelect from './components/DueDateSelect'
import PaymentStatusSelect from './components/PaymentStatusSelect'
import DistributorSelect from './components/DistributorSelect'
import PowerPlantSelect from './components/PowerPlantSelect'
import DownloadExcelModal from './components/DownloadExcelModal'
import { getPaymentColumns } from './utils'
import { EditPaymentModal } from '../../components/Modal/Payments/EditPaymentModal'
import { TableV2 } from '../../components/TableV2'
import { TablePagination } from '../../components/TableV2/components/TablePagination'
import { FloatingCheckboxes } from '../../components/FloatingCheckboxes'
import { ReactComponent as SendIcon } from '../../assets/send.svg'
import { SettingsOutlined } from '@mui/icons-material'

// Temporary code, remove after refactoring this page
const paymentStatusMap: Record<string, string> = {
  '1': 'Cobrado',
  '2': 'Não cobrado',
  '3': 'Recebido',
  '4': 'Vencido',
}

const legacyClients = [2657, 1540] // Allgreen, Atua

export function Payments() {
  const { state } = useContext(GlobalContext)
  const isALegacyClient = state.auth?.customer?.id
    ? legacyClients.includes(state.auth?.customer?.id)
    : false

  const [modalExcel, setModalExcel] = useState(false)

  // Table
  const [payments, setPayments] = useState<PaymentsBalanceSheet>()

  // Cards
  const [paymentsInfo, setPaymentsInfo] = useState<PaymentsInfoData>()

  const [previewingInvoiceClosingId, setPreviewingInvoiceClosingId] = useState<number>()
  const [isSendAllOpen, setIsSendAllOpen] = useState(false)

  const [selectedPayment, setSelectedPayment] = useState<any>()

  // Filters
  const [day, setDay] = useState(0)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState<number>()
  const [search, setSearch] = useState<string>('')
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [sortValues, setSortValues] = useState<Sort>()
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [powerPlantsIdsToFilter, setPowerPlantsIdsToFilter] = useState<number[]>([])
  const [compensationModalityFilter, setCompensationModalityFilter] = useState<string>()
  const [distributorsIdsToFilter, setDistributorsIdsToFilter] = useState<number[]>([])

  const [isLoading, setLoading] = useState<boolean>(true)

  async function getPaymentsInfoData() {
    try {
      const filters = {
        ...(search ? { search } : {}),
        ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      }

      let data

      if (isALegacyClient) {
        const { data: result } = await paymentsInfoGetAllgreen({
          page,
          filter: filters,
          limit: pageItemsAmount,
          sort: sortValues?.field,
          order: sortValues?.order,
          distributorsIds: distributorsIdsToFilter ?? [],
          powerPlantsIds: powerPlantsIdsToFilter ?? [],
          status: status ? paymentStatusMap[String(status)] : '',
          year: dateSelected.year,
          month: dateSelected.month,
          day,
        })

        data = result
      } else {
        const { data: result } = await paymentsInfoGet({
          page,
          filter: filters,
          distributorsId: distributorsIdsToFilter ?? [],
          powerPlantsId: powerPlantsIdsToFilter ?? [],
          status: status ? paymentStatusMap[String(status)] : '',
          monthReference: `${dateSelected.year}-${String(dateSelected.month).padStart(2, '0')}-01`,
          day: day > 0 ? day : undefined,
        })

        data = result
      }

      if (data) {
        setPaymentsInfo(data)
      }
    } catch (error) {
      toast({
        type: 'error',
        message: 'Erro ao carregar os dados de pagamentos.',
      })
    }
  }

  async function getData() {
    try {
      const filters = {
        ...(search ? { search } : {}),
        ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      }
      let data
      if (isALegacyClient) {
        data = await paymentsService.getBalanceSheetAllgreen({
          page,
          itemsPerPage: pageItemsAmount,
          sort:
            sortValues && sortValues.field && sortValues.order
              ? {
                  field: sortValues.field,
                  order: sortValues.order as any,
                }
              : undefined,
          distributorsId: distributorsIdsToFilter ?? [],
          powerPlantsId: powerPlantsIdsToFilter ?? [],
          monthsRefs: [`${dateSelected.year}-${String(dateSelected.month).padStart(2, '0')}-01`],
          day: day > 0 ? day : undefined,
          status,
          filter: filters,
        })
      } else {
        data = await paymentsService.getBalanceSheet({
          page,
          itemsPerPage: pageItemsAmount,
          sort:
            sortValues && sortValues.field && sortValues.order
              ? {
                  field: sortValues.field,
                  order: sortValues.order as any,
                }
              : undefined,
          distributorsId: distributorsIdsToFilter ?? [],
          powerPlantsId: powerPlantsIdsToFilter ?? [],
          monthReference: `${dateSelected.year}-${String(dateSelected.month).padStart(2, '0')}-01`,
          dueDayReference: day > 0 ? day : undefined,
          status,
          filter: filters,
        })
      }

      setPayments(data)
    } catch (error) {
      console.log({ error })
      toast({
        type: 'error',
        message: 'Erro ao carregar os dados de pagamentos.',
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await Promise.all([getData(), getPaymentsInfoData()])
      setLoading(false)
    })()
  }, [
    page,
    distributorsIdsToFilter,
    powerPlantsIdsToFilter,
    status,
    sortValues,
    dateSelected,
    pageItemsAmount,
    day,
    search,
    compensationModalityFilter,
  ])

  const handleEdit = (value: number) => {
    setSelectedPayment(value)
    setIsSendOpen(true)
  }

  const handleSendCharge = (value: any) => {
    setSelectedPayment(value)
    setIsEditOpen(true)
  }

  const { toast } = useToast()

  const handleSendAllCharges = async () => {
    if (!selectedRowsIds.length) {
      toast({
        type: 'error',
        message: 'Selecione pelo menos uma cobrança.',
      })
      return
    }

    setIsSendAllOpen(true)
  }

  const { checkPermission } = usePermissions()

  const hasSendInvoicePermission = checkPermission(PermissionType.PAYMENTS_SEND_INVOICE)
  const hasEditingPermission = checkPermission(PermissionType.PAYMENTS_UPDATE)

  const printHandler = (paymentId: number) => {
    const payment = (payments?.data || []).find((item) => item.fechamento_id === paymentId)
    if (payment && payment.drive_id) {
      const url = `${process.env.REACT_APP_BASE_URL}/faturas/download/${payment.drive_id}`
      window.open(url, '_blank')
    }
  }

  const rows = payments?.data || []

  const [columnsSelected, setColumnsSelected] = useState<string[]>([]);

  const defaultTableColumns = useMemo(
    () =>
      getPaymentColumns({
        previewHandler: (id) => setPreviewingInvoiceClosingId(id),
        sendHandler: handleSendCharge,
        editHandler: handleEdit,
        printHandler: printHandler,
        permissions: {
          hasSendInvoicePermission,
          hasEditingPermission,
        },
        isALegacyClient,
        columnsSelected,
      }),
    [rows]
  );
  

  const [tableColumns, setTableColumns] = useState<GridColDef[]>(defaultTableColumns);

  async function handleDownload(values: any) {
    const filters = {
      ...(search ? { search } : {}),
      ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      ...(tableColumns
        ? { fields: tableColumns.filter((col) => !col.hide).map((col) => col.field) }
        : {}),
    }

    if (isALegacyClient) {
      return await downloadCsvGetAllgreen({
        filter: filters,
        order: 'ASC',
        distributorsIds: distributorsIdsToFilter ?? [],
        powerPlantsIds: powerPlantsIdsToFilter ?? [],
        monthsRefs: values,
        status: status ? paymentStatusMap[String(status)] : '',
        day,
      }).then((response) => response.data)
    }

    return await downloadCsvGet({
      sort:
        sortValues && sortValues.field && sortValues.order
          ? {
              field: sortValues.field,
              order: sortValues.order as any,
            }
          : undefined,
      distributorsId: distributorsIdsToFilter ?? [],
      powerPlantsId: powerPlantsIdsToFilter ?? [],
      monthReference: values,
      dueDayReference: day > 0 ? day : undefined,
      status,
      filter: filters,
    }).then((response) => response.data)
  }

  // const deleteFunction = async () => {
  //   try {
  //     setLoading(true)
  //     await deletePayment(selectedPayment)
  //     setIsCancelPaymentOpen(false)
  //     await getData()
  //   } catch (error) {
  //     console.error(error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([])

  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isSendOpen, setIsSendOpen] = useState(false)
  // const [isCancelPaymentOpen, setIsCancelPaymentOpen] = useState(false)

  useEffect(() => {
    if (!rows?.length) return
    setTableColumns(defaultTableColumns)
  }, [defaultTableColumns])

  return (
    <PaymentsContainer>
      {modalExcel && (
        <DownloadExcelModal
          dateSelected={dateSelected}
          onDownload={handleDownload}
          onClose={() => setModalExcel(false)}
        />
      )}

      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      {previewingInvoiceClosingId && (
        <PreviewInvoiceModal
          dateSelected={dateSelected}
          invoiceClosingId={previewingInvoiceClosingId}
          onClose={() => setPreviewingInvoiceClosingId(undefined)}
        />
      )}
      <WidgetsContainer style={{ marginTop: '24px' }}>
        <WidgetPayments
          Icon={DollarIcon}
          title='Faturamento'
          value={paymentsInfo?.faturamentoTotal}
          isLoading={isLoading}
        />
        <WidgetPayments
          Icon={ReceivedAmountIcon}
          title='Valores Recebidos'
          value={paymentsInfo?.recebidos}
          isLoading={isLoading}
        />
        <WidgetPayments
          Icon={ReceiptX}
          title='Valores Vencidos'
          value={paymentsInfo?.vencidos}
          isLoading={isLoading}
        />
        <WidgetPayments
          Icon={NonPaymentIcon}
          title='Inadimplência'
          value={paymentsInfo?.inadimplencia}
          isPercentage
          isLoading={isLoading}
        />
      </WidgetsContainer>
      <SettingsContainer>
        <FiltersContainer>
          <DistributorSelect
            value={distributorsIdsToFilter}
            onChange={(distributorsIds) => {
              setDistributorsIdsToFilter(distributorsIds)
              setPage(1)
            }}
          />
          <PowerPlantSelect
            value={powerPlantsIdsToFilter}
            onChange={(powerPlantsIds) => {
              setPowerPlantsIdsToFilter(powerPlantsIds)
              setPage(1)
            }}
          />
          <DueDateSelect dateSelected={dateSelected} value={day} onChange={setDay} />
          <CompensationModalityFilter
            compensationModalityFilter={compensationModalityFilter}
            setCompensationModalityFilter={setCompensationModalityFilter}
          />
          <PaymentStatusSelect
            value={status}
            onChange={(selectedStatus) => {
              setStatus(selectedStatus)
              setPage(1)
            }}
          />
        </FiltersContainer>
        <ConfigButtonsColumn>
          <SearchInput
            onSearch={(text) => {
              setPage(1)
              setSearch(text)
            }}
          />

          <ActionTableButton onClick={() => setModalExcel(true)} icon={<DownloadIcon />} />

          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableColumns.map((col) => ({
              label: col.headerName || '',
              checked: !col.hide,
              id: Math.random(),
            }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={(values) => {
              setTableColumns((previousColumns) =>
                previousColumns.map((col) => ({
                  ...col,
                  hide: !values.find((val) => val.label === col.headerName)?.checked,
                })),
              )

              setColumnsSelected(values.filter((val) => val.checked).map((val) => val.label))
            }}
          />

          {hasSendInvoicePermission && (
            <ActionTableButton onClick={handleSendAllCharges} icon={<SendIcon />} />
          )}
        </ConfigButtonsColumn>
      </SettingsContainer>
      <>
        {previewingInvoiceClosingId && (
          <PreviewInvoiceModal
            dateSelected={dateSelected}
            invoiceClosingId={previewingInvoiceClosingId}
            onClose={() => setPreviewingInvoiceClosingId(undefined)}
          />
        )}

        {isEditOpen && (
          <EditPaymentModal
            openModal={isEditOpen}
            setModalOpen={setIsEditOpen}
            confirmCharge
            receiveChargeId={selectedPayment}
            receivePaymentData={payments?.data || []}
            postAction={getData}
            emailsList={
              (payments?.data ?? []).find((payment) => payment.fechamento_id === selectedPayment)
                ?.emails || ['']
            }
            selectedDate={dateSelected}
          />
        )}

        {/* {isCancelPaymentOpen && (
        <ModalDelete
          openModal={isCancelPaymentOpen}
          setModalOpen={setIsCancelPaymentOpen}
          confirmText
          buttonColor='darkGreen'
          text='Tem certeza que deseja cancelar a cobrança?'
          action={deleteFunction}
        />
      )} */}

        {isSendOpen && (
          <EditPaymentModal
            openModal={isSendOpen}
            setModalOpen={setIsSendOpen}
            receiveChargeId={selectedPayment}
            receivePaymentData={payments?.data ?? []}
            postAction={getData}
            selectedDate={dateSelected}
          />
        )}

        {isSendAllOpen && (
          <EditPaymentModal
            openModal={isSendAllOpen}
            setModalOpen={setIsSendAllOpen}
            sendAll={isSendAllOpen}
            selectedIds={selectedRowsIds}
            postAction={getData}
            selectedDate={dateSelected}
          />
        )}

        <TableV2
          columns={tableColumns}
          rows={payments?.data?.map((item) => ({ ...item, id: item.fechamento_id })) || []}
          alignText='center'
          sort={sortValues}
          onSort={(sort) => {
            setSortValues(sort)
            setPage(1)
          }}
          loading={isLoading}
          serverSorting
          messageNoRow='Não há pagamentos.'
          isSelectable={true}
          selectedItems={selectedRowsIds}
          setSelectedItems={(items) => {
            const ids = (payments?.data || [])
              .filter((payment) => payment.status_ativacao_uc !== 'DISABLED')
              .filter((payment) => items.includes(payment.fechamento_id))
              .map((payment) => payment.fechamento_id)
            setSelectedRowsIds(ids)
          }}
        >
          <TablePagination
            pageInfo={{
              totalCount: payments?.pageInfo?.totalCount || 0,
              currentPage: payments?.pageInfo?.currentPage || 1,
              totalPages: payments?.pageInfo?.totalPages || 1,
              limit: payments?.pageInfo?.limit || 30,
            }}
            currentPageItemCount={payments?.data?.length ?? 0}
            onChange={setPage}
          />
        </TableV2>
      </>
    </PaymentsContainer>
  )
}
